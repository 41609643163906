import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faSchool,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const ContactLink = ({ icon, href, text, external }) => (
  <a
    href={href}
    target={external ? "_blank" : "_self"}
    rel={external ? "noreferrer" : ""}
    className="group flex items-center space-x-4 p-4 border border-neutral-800 hover:border-white transition-colors duration-300"
  >
    <FontAwesomeIcon
      icon={icon}
      className="text-neutral-400 group-hover:text-white transition-colors duration-300 w-5 h-5"
    />
    <span className="text-neutral-400 group-hover:text-white transition-colors duration-300">
      {text}
    </span>
  </a>
);

const Contact = () => {
  const contactLinks = [
    {
      icon: faEnvelope,
      href: "mailto:aryanp862002@gmail.com",
      text: "aryanp862002@gmail.com",
      external: false,
    },
    {
      icon: faSchool,
      href: "mailto:patea156@mcmaster.ca",
      text: "patea156@mcmaster.ca",
      external: false,
    },
    {
      icon: faPhone,
      href: "tel:519-328-1159",
      text: "519-328-1159",
      external: false,
    },
  ];

  const socialLinks = [
    {
      icon: faGithub,
      href: "https://github.com/Aryanpatel335",
      text: "GitHub",
      external: true,
    },
    {
      icon: faLinkedin,
      href: "https://www.linkedin.com/in/aryanpatel555/",
      text: "LinkedIn",
      external: true,
    },
  ];

  return (
    <div
      id="contact"
      className="min-h-screen flex items-center justify-center bg-black px-4 py-20"
    >
      <div className="max-w-2xl w-full">
        {/* Section Header */}
        <h2 className="text-2xl md:text-4xl font-light mb-16 tracking-wider">
          <span className="text-neutral-500">04.</span>
          <span className="text-white ml-2">contact</span>
        </h2>

        {/* Contact Links */}
        <div className="space-y-4 mb-8">
          {contactLinks.map((link, index) => (
            <ContactLink key={index} {...link} />
          ))}
        </div>

        {/* Social Links */}
        <div className="flex flex-col md:flex-row gap-4">
          {socialLinks.map((link, index) => (
            <ContactLink key={index} {...link} />
          ))}
        </div>

        {/* Footer Note */}
        <p className="text-neutral-500 text-sm mt-16 text-center">
          Feel free to reach out for collaborations or just to say hi!
        </p>
      </div>
    </div>
  );
};

export default Contact;
