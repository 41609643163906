import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Nav = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const NavLink = ({ href, children }) => (
    <li className="p-4">
      <a
        href={href}
        className="text-neutral-400 hover:text-white transition-colors relative group"
        onClick={() => setNav(false)}
      >
        {children}
        <span className="absolute left-0 -bottom-1 w-0 h-px bg-white transition-all group-hover:w-full"></span>
      </a>
    </li>
  );

  return (
    <>
      {/* Main Navigation Bar */}
      <div className="fixed top-0 left-0 w-full z-40 bg-black/90 backdrop-blur-sm">
        <div className="flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4 text-white">
          <h1 className="text-2xl font-light tracking-wider">ap</h1>

          {/* Desktop Menu */}
          <ul className="hidden md:flex items-center space-x-2">
            <NavLink href="#about">about</NavLink>
            <NavLink href="#experience">experience</NavLink>
            <NavLink href="#skills">skills</NavLink>
            <NavLink href="#contact">contact</NavLink>
          </ul>

          {/* Mobile Menu Button */}
          <div onClick={handleNav} className="block md:hidden">
            <AiOutlineMenu
              size={20}
              className="text-neutral-400 hover:text-white transition-colors"
            />
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay - Separate from main navigation */}
      {nav && (
        <div className="fixed inset-0 z-50 md:hidden">
          {/* Full screen overlay */}
          <div className="fixed inset-0 bg-black" />

          {/* Menu Content */}
          <div className="fixed inset-0 flex flex-col bg-black">
            {/* Header */}
            <div className="flex justify-between items-center p-4 border-b border-neutral-800">
              <h1 className="text-2xl font-light tracking-wider text-white">
                ap
              </h1>
              <button
                onClick={handleNav}
                className="text-neutral-400 hover:text-white transition-colors"
              >
                <AiOutlineClose size={20} />
              </button>
            </div>

            {/* Navigation Links */}
            <ul className="flex flex-col p-4">
              <NavLink href="#about">about</NavLink>
              <NavLink href="#experience">experience</NavLink>
              <NavLink href="#skills">skills</NavLink>
              <NavLink href="#contact">contact</NavLink>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Nav;
