import React from "react";

const Skills = () => {
  const skillCategories = {
    languages: [
      "Java",
      "JavaScript",
      "TypeScript",
      "Python",
      "HTML",
      "CSS",
      "C#",
    ],
    "frameworks & libraries": [
      "React",
      "Next.js",
      "Node.js",
      "Express.js",
      "Spring Boot",
      "Flask",
      "ASP.NET",
      "Redux",
    ],
    "tools & technologies": [
      "Git",
      "Docker",
      "Jenkins",
      "GCP",
      "MySQL",
      "MongoDB",
      "VS Code",
      "IntelliJ",
      "Jira",
      "Confluence",
      "Postman",
    ],
  };

  return (
    <div
      id="skills"
      className="min-h-screen flex items-center justify-center bg-black px-6 py-20"
    >
      <div className="max-w-5xl w-full">
        {/* Section Header */}
        <div className="mb-16">
          <h2 className="text-2xl md:text-4xl font-light tracking-wider">
            <span className="text-neutral-500">03.</span>
            <span className="text-white ml-2">skills</span>
          </h2>
        </div>

        {/* Skills Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {Object.entries(skillCategories).map(([category, skills]) => (
            <div key={category} className="space-y-6">
              <h3 className="text-neutral-400 text-lg font-light tracking-wider">
                {category}
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.map((skill) => (
                  <span
                    key={skill}
                    className="inline-block px-3 py-1.5 text-sm 
                             border border-neutral-800 text-neutral-300
                             hover:border-white hover:text-white
                             transition-colors duration-300 ease-in-out
                             cursor-default"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Additional Skills Note */}
        <p className="text-neutral-500 text-sm mt-12 italic">
          *Currently exploring: Cloud Architecture, System Design, and Data
          Engineering
        </p>
      </div>
    </div>
  );
};

export default Skills;
