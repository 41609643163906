import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-neutral-400 py-8 px-4">
      <div className="max-w-5xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <div className="text-sm tracking-wider">
          <span className="font-light">designed & built by</span>{" "}
          <span className="text-white">aryan patel</span>
        </div>

        <div className="flex items-center space-x-2 text-xs">
          <span className="text-neutral-500">© 2024</span>
          {/* <span className="h-1 w-1 rounded-full bg-neutral-700"></span> */}
        </div>
      </div>

      {/* Optional: Signature Line */}
      <div className="max-w-5xl mx-auto mt-4 pt-4 border-t border-neutral-900">
        <p className="text-center text-neutral-600 text-xs">
          made with ☕ in hamilton, on
        </p>
      </div>
    </footer>
  );
};

export default Footer;
