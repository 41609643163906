import React from "react";

const About = () => {
  return (
    <div
      id="about"
      className="min-h-screen flex items-center justify-center bg-black px-4 py-20"
    >
      <div className="max-w-3xl">
        <h2 className="text-2xl md:text-4xl font-light mb-16 tracking-wider">
          <span className="text-neutral-500">01.</span>
          <span className="text-white ml-2">about me</span>
        </h2>

        <div className="space-y-6 text-neutral-400 text-lg leading-relaxed">
          <p>
            Currently pursuing Software Engineering at{" "}
            <span className="text-white">McMaster University</span> with a{" "}
            <span className="text-white">3.9/4.0 GPA</span>, I'm passionate
            about building scalable solutions and exploring innovative
            technologies.
          </p>

          <p>
            Through internships at{" "}
            <span className="text-white">Bell Canada</span>,{" "}
            <span className="text-white">Univeris</span>, and{" "}
            <span className="text-white">OpenText</span>, I've gained extensive
            experience in full-stack development, cloud computing, and data
            engineering.
          </p>

          <p>
            In my free time, I work on{" "}
            <span className="text-white">mirco-saas applications</span> in order
            to improve my skills as well as to potentially launch a{" "}
            <span className="text-white">startup</span> in the future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
