import React from "react";
import Nav from "./Nav";

const Intro = () => {
  return (
    <div className="relative min-h-screen bg-black">
      <div className="absolute top-0 left-0 z-20 w-full">
        <Nav />
      </div>

      <div className="flex flex-col justify-center items-center min-h-screen px-4 md:px-8">
        <div className="max-w-4xl w-full space-y-6 text-left">
          <h1 className="text-4xl md:text-7xl font-bold tracking-tight">
            <span className="text-neutral-500">hi, i'm</span>
            <br />
            <span className="text-white hover:text-neutral-200 transition-colors">
              aryan patel
            </span>
          </h1>

          <h2 className="text-xl md:text-3xl text-neutral-400 font-light">
            software engineering student
          </h2>

          <p className="text-neutral-400 max-w-2xl text-lg md:text-xl leading-relaxed">
            passionate about building helpful applications and solving complex
            problems through code.
          </p>

          <div className="flex gap-4 pt-4">
            <a
              href="https://github.com/Aryanpatel335"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-white text-black hover:bg-neutral-200 rounded-none transition-colors"
            >
              View Projects
            </a>
            <a
              href="https://www.linkedin.com/in/aryanpatel555/"
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 border border-white text-white hover:bg-white hover:text-black rounded-none transition-all"
            >
              Get in Touch
            </a>
          </div>
        </div>

        <div className="absolute bottom-8 left-0 right-0 flex justify-center">
          <div className="w-6 h-10 border-2 border-neutral-400 rounded-full flex justify-center hover:border-white transition-colors">
            <div className="w-1 h-3 bg-neutral-400 rounded-full mt-2 animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
