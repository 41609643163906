import Intro from "./components/Intro";
import About from "./components/About";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
// import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
function App() {
  return (
    <div className="bg-black min-h-screen">
      <main>
        <Intro />
        <About />
        <Experience />
        <Skills />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
