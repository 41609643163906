import React from "react";

const ExperienceCard = ({ role, company, period, description, tech }) => (
  <div className="group border border-neutral-800 p-8 hover:border-white transition-colors duration-300">
    <div className="space-y-4">
      <div>
        <h3 className="text-xl text-white font-light">{role}</h3>
        <p className="text-neutral-400">
          {company} • {period}
        </p>
      </div>
      <p className="text-neutral-300">{description}</p>
      <div className="text-neutral-500 text-sm space-x-2">
        {tech.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </div>
    </div>
  </div>
);

const Experience = () => {
  const experiences = [
    {
      role: "Software Engineer Intern",
      company: "Bell Canada",
      period: "May 2024 - Aug 2024",
      description:
        "Led data engineering initiatives using GCP, optimizing query performance and automating data pipelines.",
      tech: ["Python", "GCP", "BigQuery", "Terraform", "Airflow"],
    },
    {
      role: "Software Engineer Intern",
      company: "Univeris",
      period: "May 2023 - Aug 2023",
      description:
        "Developed microservices for automated report delivery and enhanced database performance.",
      tech: ["Java", "Spring", "SQL", "JavaScript", "Grafana"],
    },
    {
      role: "Software Engineer Intern",
      company: "OpenText",
      period: "Jan 2023 - Apr 2023",
      description:
        "Built REST services and improved admin dashboard performance using modern web technologies.",
      tech: ["Java", "React", "Next.js", "REST API", "Spring"],
    },
  ];

  return (
    <div
      id="experience"
      className="min-h-screen flex items-center justify-center bg-black px-4 py-20"
    >
      <div className="max-w-4xl w-full">
        <h2 className="text-2xl md:text-4xl font-light mb-16 tracking-wider">
          <span className="text-neutral-500">02.</span>
          <span className="text-white ml-2">experience</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {experiences.map((exp, index) => (
            <ExperienceCard key={index} {...exp} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
